import React, { useRef, useState } from "react"
import { css, cx } from "emotion"
import { Cell, Grid, Breakpoints, colors, typography, spacing } from "@avonova-noa/common"
import axios from "axios";
import { useForm } from "react-hook-form"

const newsletterFormCSS = css`
    background-color: ${colors.green.dark2};
    color: #fff;
    text-align: center;
    padding: ${spacing.xxl} 0;
`
const inputCSS = css`
    width:100%; 
    height: 52px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 5px 0 0 5px;
    background: transparent;
    color: #fff;
    padding: 0 16px;
    ${typography.body} 
    &:focus {
        outline: none;
        box-shadow: inset 0 0 1px 1px #51a7e8;
    }
    ::-webkit-input-placeholder { /* Edge */
        color: ${colors.green.light4};
    }
    ::placeholder {
        color: ${colors.green.light4};
    }
    @media screen and (${Breakpoints.mobile}){
        border-radius: 5px;
        text-align: center;
    }
`
const buttonCSS = css`
    color: #fff;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-width: 1px 1px 1px 0;
    border-radius: 0 5px 5px 0;
    font-weight: 500;
    padding: 0;
    span {
        display: block;
        padding: 0 16px;
        height: 50px;
        line-height: 50px;
        &:focus {
                outline: none;
            }
        @media screen and (${Breakpoints.mobile}){
            padding: 0 80px;
        }
    }
   
    ${typography.body}

    @media screen and (${Breakpoints.mobile}){
        border-radius: 5px;
        width: 100%;
        border-width: 1px;
    }

    &:hover {
        color: ${colors.green.dark2};
        background-color: #fff;
    }

    &:focus {
        outline: none;
        &>span {
            box-shadow: inset 0 0 2px 2px #51a7e8;
            
        }
    }
`
const msgCSS = css`
    ${typography.body}
`
const errorMsgCSS = css`
    ${msgCSS}
    color: ${colors.red.red1};
`
const labelCSS = css`
    ${typography.subtitle}
    margin-bottom: 32px;
    text-transform: none;
    color: #fff;

    @media screen and (${Breakpoints.mobile}){
        margin-bottom: 24px;
        display: block;
    }
`
export const NewsletterForm: React.FC = () => {
    const onSubmit = (data: { emailField: string }) => {
        axios.post("https://api.hsforms.com/submissions/v3/integration/submit/7959634/cecf9400-f7c7-49d3-a33f-7bdbec744c08",
            {
                "fields": [
                    {
                        "name": "email",
                        "value": data.emailField
                    }
                ],
                "legalConsentOptions": {
                    "consent": {
                        "consentToProcess": true,
                        "text": "Ved innsending av dette skjemaet samtykker du til at vi kan lagre og behandle dine personopplysninger, samt at vi kan sende deg relevant informasjon på e-post, som tips og nyttige artikler. Du kan når som helst endre dine preferanser for kommunikasjon. Mer info i vår <a className={css`color: #fff;`} href=`https://www.avonova.no/om-avonova-helse/personvern-kunde-og-pasient-data/`>personvernerklæring.",
                    }
                }
            }
        ).then(function () {
            setError(false);
            setSuccess(true);
        }).catch(function () {
            setError(true);
            setSuccess(false);
        })
    }

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const isEmail = (email: string) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    return (
        <div className={newsletterFormCSS} >
            <form onSubmit={handleSubmit(onSubmit)} className={css`border: none;`} noValidate>
                <Grid maxWidth="620px" columns={"1fr auto"} columnGap="0" rowGap="32" areas={
                    errors.emailField ?
                        [
                            "label label",
                            "gdpr gdpr",
                            "input button",
                            "emailMsg emailMsg",
                            "msg msg"
                        ] : [
                            "label label",
                            "gdpr gdpr",
                            "input button",
                            "msg msg"
                        ]
                } mobile={{
                    columns: 1,
                    gap: "16px",
                    areas:
                        errors.emailField ?
                            [
								"label",
                                "gdpr",
                                "input",
                                "emailMsg",
                                "button",
                                "msg"
                            ] : [
                                "label",
                                "gdpr",
                                "input",
                                "button",
                                "msg"
                            ]
                    ,
                }}>
                    <Cell area="label">
                        <label className={labelCSS} htmlFor="emailField">Få Helsehjulet rett i innboksen</label>
                    </Cell>
                    <Cell area="gdpr">
                        <p className={typography.body}>
                            Ved innsending av dette skjemaet samtykker du til at vi kan lagre og behandle dine personopplysninger, samt at vi kan sende deg relevant informasjon på e-post, som tips og nyttige artikler. Du kan når som helst endre dine preferanser for kommunikasjon. Mer info i vår  <a className={css`color: #fff;`} href="https://www.avonova.no/om-avonova-helse/personvern-kunde-og-pasient-data/">personvernerklæring.</a></p></Cell>
                    <Cell area="input">
                        <input
                            onChange={() => { success || error && setError(false), setSuccess(false) }}
                            ref={register({ required: true, validate: (input: string) => isEmail(input) })}
                            className={cx(inputCSS, css`${errors.emailField && "box-shadow: inset 0 0 1px 1px " + colors.red.red3};`)}
                            id="emailField"
                            name="emailField"
                            type="email"
                            placeholder="Epost-adresse" />
                    </Cell>
                    <Cell area="button">
                        <button type="submit" className={buttonCSS} >
                            <span tabIndex={-1}>Send inn</span>
                        </button>
                    </Cell>

                    {
                        errors.emailField &&
                        <Cell area="emailMsg">
                            <p className={errorMsgCSS}>Vennligst angi gyldig e-postadresse.</p>
                        </Cell>
                    }{success &&
                        <Cell area="msg">
                            <p className={msgCSS}>Takk for påmeldingen!</p>
                        </Cell>
                    }{error &&
                        <Cell area="msg">
                            <p className={errorMsgCSS}>Det har oppstått en feil, Prøv igjen senere.</p>
                        </Cell>
                    }
                </Grid>
            </form>
        </div >
    )
}