import React from "react";
import Img, { FluidObject } from "gatsby-image"
import { css } from "emotion";
import { Button } from "@avonova-noa/components";
import { spacing, typography } from "@avonova-noa/common";
import { skins } from "./button";

export interface PersonBlockProps {
    name: string
    title: string
    email: string,
    image: {
        title: string,
        fluid: FluidObject
    }
}

const personCSS = css`
    text-align: center;

    h4 {
        ${typography.h6}
        margin-bottom: 8px;
    }

    p {
        ${typography.overline}
        margin-bottom: 8px;
    }

    .gatsby-image-wrapper {
        border-radius: 100%;
        margin-bottom: ${spacing.ml};
        max-width: 260px;
        max-height: 260px;
        margin-left: auto;
        margin-right: auto;
    }
`

export const PersonBlock = ({ name, title, email, image }: PersonBlockProps) => {
    return (
        <div className={personCSS}>
            {
                image && <Img
                    alt={image.title}
                    fluid={image.fluid}
                />
            }

            <h4>{name}</h4>
            <p>{title}</p>
            <Button as="link" to={`mailto:${email}`} buttonText={email} skin={skins.underlined}></Button>
        </div>
    )
}

