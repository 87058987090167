import React from "react"
import { css } from "emotion"
import { Link } from "gatsby"
import { Breakpoints, colors, spacing, typography } from "@avonova-noa/common"
import { Button, skins } from "@avonova-noa/components"
const landscape = require("../assets/landscape.svg") as string;

const IllustrationBlockCSS = css`
    background: ${colors.neutral.light1};
    background-image: url(${landscape});
    background-position: bottom;
    display: block;
    padding: 0 ${spacing.s};
    padding-top: ${spacing.giant};
    padding-bottom: 240px;
    text-align: center;
    background-repeat: no-repeat;
    margin-top: ${spacing.giant};
    background-repeat: repeat-x;
   
    .content {
        max-width: 658px;
        margin: 0 auto;
    }

    h2 {
        ${typography.h3}
        margin-bottom: ${spacing.ml};
    }

    p{
        ${typography.bodySerif}
        color: ${colors.green.green11};
        margin-bottom: ${spacing.ml};
    }


    @media screen and (${Breakpoints.mobile}) {
        padding-top: ${spacing.xxl};
        margin-top: ${spacing.xxl};

        h2 {
            ${typography.h3}
        }

    }
`

interface IllustrationBlockProps {
    header: string,
    description: { description: string },
    destinationLink: {
        slug: string
    }
}

const IllustrationBlock = ({ header, description, destinationLink }: IllustrationBlockProps) => {
    return (
        <div className={IllustrationBlockCSS} >
            <div className="content">
                <h2>{header}</h2>
                <p>{description.description}</p>
            </div>
            <Button as="link" buttonText="Les mer" to={destinationLink.slug} skin={skins.primary}></Button>
        </div>
    )
}

export { IllustrationBlock, IllustrationBlockProps } 