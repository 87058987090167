export const colors = {
  green: {
    green1: "#E0FFF9" as const,
    green2: "#AFF0E5" as const,
    green3: "#7EEEDB" as const,
    green4: "#4EE8CC" as const,
    green5: "#27DDBB" as const,
    green6: "#09CCA6" as const,
    green7: "#00AD8C" as const,
    green8: "#00866D" as const,
    green9: "#005646" as const,
    green10: "#01332A" as const,
    green11: "#00211B" as const,
    // new variable names above, delete old variables beneath after replaced with new variable names
    dark1: "#00211B" as const,
    dark2: "#01332A" as const,
    dark3: "#005646" as const,
    dark4: "#00866D" as const,
    medium1: "#00AD8C" as const,
    medium2: "#09CCA6" as const,
    medium3: "#27DDBB" as const,
    light1: "#4EE8CC" as const,
    light2: "#7EEEDB" as const,
    light3: "#AFF0E5" as const,
    light4: "#E0FFF9" as const,
  },
  red: {
    red1: "#FFBAAE" as const,
    red2: "#FF6C62" as const,
    red3: "#FC4B47" as const,
    red4: "#A72323" as const,
    // new variable names above, delete old variables beneath after replaced with new variable names
    dark1: "#A72323" as const,
    medium1: "#FC4B47" as const,
    medium2: "#FF6C62" as const,
    light1: "#FFBAAE" as const,
  },
  // remove yellow when checked that variables are not in used anywhere, as it doesn't exist in final palette
  yellow: {
    dark1: "#FE8800" as const,
    medium1: "#F9A035" as const,
    medium2: "#FFDCAF" as const,
    light1: "#FFF6EB" as const,
  },
  purple: {
    purple1: "#EDDDFF" as const,
    purple2: "#CB9CFF" as const,
    purple3: "#9242EB" as const,
    purple4: "#500A9E" as const,
    // new variable names above, delete old variables beneath after replaced with new variable names
    dark1: "#500A9E" as const,
    medium1: "#9242EB" as const,
    medium2: "#CB9CFF" as const,
    light1: "#EDDDFF" as const,
  },
  neutral: {
    neutral1: "#FAF7F6" as const,
    neutral2: "#F5E7E1" as const,
    neutral3: "#DAAA93" as const,
    neutral4: "#B07F68" as const,
    neutral5: "#7D594A" as const,
    neutral6: "#593D34" as const,
    // new variable names above, delete old variables beneath after replaced with new variable names
    dark1: "#593D34" as const,
    dark2: "#7D594A" as const,
    medium1: "#B07F68" as const,
    medium2: "#DAAA93" as const,
    light1: "#F5E7E1" as const,
    light2: "#FAF7F6" as const,
  },
  gray: {
    gray1: "#FAFAFA" as const,
    gray2: "#F5F5F5" as const,
    gray3: "#DADADA" as const,
    gray4: "#B0B0B0" as const,
    gray5: "#7D7D7D" as const,
    gray6: "#595959" as const,
    // new variable names above, delete old variables beneath after replaced with new variable names
    dark1: "#595959" as const,
    dark2: "#7D7D7D" as const,
    medium1: "#B0B0B0" as const,
    medium2: "#DADADA" as const,
    light1: "#F5F5F5" as const,
    light2: "#FAFAFA" as const,
  },
  orange: {
    orange1: "#FFF6EB" as const,
    orange2: "#FFDCAF" as const,
    orange3: "#F9A035" as const,
    orange4: "#FE8800" as const,
  },
  white: { white: "#FFFFFF" as const},
  black: { black: "#000000" as const}
}
