import React, { useState } from "react";
import ReactPlayer from 'react-player'

export interface VideoBlockProps {
    url?: string
    width: string
    height: string
}

export const VideoBlock = ({ url, width, height }: VideoBlockProps) => {
    const [showControls, setShowControls] = useState(false);
    return (
        <ReactPlayer className="reactPlayer" controls={true} onMouseEnter={() => { setShowControls(true) }} onMouseLeave={() => { setShowControls(false) }} url={url} width={width} height={height} />
    )
}