import React from "react"

const IconArrowRight = () => (
  <span className="icon_wrap">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 8.5H15.5" stroke="#27DDBB" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.5 3.5L15.5 8.5L10.5 13.5" stroke="#27DDBB" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </span>
)

export default IconArrowRight
