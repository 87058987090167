import { Breakpoints, Cell, Grid, spacing, typography } from "@avonova-noa/common";
import { Button, skins } from "@avonova-noa/components";
import { css } from "emotion";
import React from "react";

export interface ContactBlockProps {
    header: string;
    contacts: {
        title: string,
        email: string
    }[]
}

const contactBlockCSS = css`
    padding: ${spacing.giant} ${spacing.s};

    ul, li {
        list-style: none;
        padding: 0;
        text-align: center;
    }

    h4 {
        ${typography.h6}
        display: inline-block;

        @media (${Breakpoints.tablet}){
            display: block;
            margin: 0 0 ${spacing.xs};
        }

        @media (${Breakpoints.mobile}){
            margin: 0 0 5px;
        }
    }
 
    a:last-child {
        ${typography.h6Bold}
        text-transform: none;

        @media (${Breakpoints.tablet}){
            margin-bottom: ${spacing.l};
            padding: 0;
        }

        @media (${Breakpoints.mobile}){
            margin-bottom: ${spacing.m};
            padding: 0;
        }
    }
`;

export const ContactBlock = ({ header, contacts }: ContactBlockProps) => {
    return (
        <div className={contactBlockCSS}>
            <Grid gap={"0"}>
                <Cell width={12}>
                    <h3 className={css`${typography.h4} text-align: center;`}>{header}</h3>
                </Cell>
                <Cell width={12}>
                    <ul>
                        {contacts.map(contact => {
                            return (<li>
                                <h4>{contact.title}</h4>
                                <Button as="link" to={`mailto:${contact.email}`} skin={skins.underlined} buttonText={contact.email}></Button>
                            </li>
                            )
                        })}
                    </ul>
                </Cell>
            </Grid>
        </div>
    )
}