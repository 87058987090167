import React from "react"
import { css } from "emotion"
import { Breakpoints, Grid, Cell, colors, typography, spacing } from "@avonova-noa/common"
import { Link } from "gatsby";
const avonovaLogo = require("./../images/avonova_logo.svg") as string;


const footerCSS = css`
    background-color: ${colors.green.green11};
    color: #fff;
    padding: ${spacing.giant} 0;
    ${typography.body}
   
    @media screen and (${Breakpoints.mobile}){
       text-align: center;
       padding: ${spacing.xxl} 0;
    }
`
const socialMediaLink = css`
    margin-bottom: 10px;
    margin-right: 8px;
    display: inline-block;
    visibility: hidden;

    @media screen and (${Breakpoints.mobile}){
        margin-bottom: ${spacing.ml};
    }
`

const avonovaLogoCss = css`
    margin-top: 8px;
    display: block;
    @media (${Breakpoints.mobile}){
        margin: 0 0 ${spacing.ml};
    }

    img {
        height: 12px;
        margin: 0;
    }
`
const linkList =
    css`
    list-style:none; 
    padding: 0;
    margin: 0; 
    margin-bottom:${spacing.ms};

    li{
        margin: 0;
    }
    a {
        color: #fff;
        text-decoration: none;
        ${typography.body}

        &:hover {
            text-decoration: underline;
        }

    
    }
`

export default () => {
    return (
        <footer className={footerCSS}>
            <Grid columns={12} mobile={{ columns: 1, rowGap: "0" }}>
                <Cell width={3} mobile={{ width: 1 }}><a className={avonovaLogoCss} href={"https://www.avonova.com/"}><img src={avonovaLogo} /></a></Cell>
                <Cell width={3} mobile={{ width: 1 }}>
                    <p className={css`opacity:0.5; margin-bottom:${spacing.ms};`}>
                        Helsehjulet er et nettmagasin fra Avonova med fokus på helhetlig helse.</p></Cell>
                <Cell width={3} left={8} mobile={{ left: 1, width: 1 }}>
                    <ul className={linkList} >
                        <li><Link to="/omhelsehjulet">Om Helsehjulet</Link></li>
                        <li><a href="mailto:hei@helsehjulet.com">hei@helsehjulet.com</a></li>
                        <li><Link to="/privacy">Personvern</Link></li>
                    </ul>
                </Cell>
                <Cell width={2} mobile={{ width: 1 }}>
                    <a href="" className={socialMediaLink}>
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M35.5 18C35.5 27.665 27.665 35.5 18 35.5C8.33502 35.5 0.5 27.665 0.5 18C0.5 8.33502 8.33502 0.5 18 0.5C27.665 0.5 35.5 8.33502 35.5 18Z" stroke="white" />
                            <path d="M21.2921 18.8192L21.6966 16.1826H19.1668V14.4717C19.1668 13.7503 19.5201 13.0472 20.6532 13.0472H21.8033V10.8025C21.128 10.6932 20.4457 10.6336 19.7617 10.624C17.678 10.624 16.3161 11.8868 16.3161 14.1731V16.1826H14V18.8192H16.3161V25.1932C17.2606 25.3412 18.2223 25.3412 19.1668 25.1932V18.8192H21.2921Z" fill="white" />
                        </svg>

                    </a>
                    <a href="" className={socialMediaLink}>
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M35.5 18C35.5 27.665 27.665 35.5 18 35.5C8.33502 35.5 0.5 27.665 0.5 18C0.5 8.33502 8.33502 0.5 18 0.5C27.665 0.5 35.5 8.33502 35.5 18Z" stroke="white" />
                            <path d="M11.7057 15.2H14.5246V24.2609H11.7057V15.2ZM13.1159 10.6958C14.0168 10.6958 14.7481 11.4274 14.7481 12.3288C14.7481 13.2304 14.0168 13.962 13.1159 13.962C12.2115 13.962 11.4822 13.2304 11.4822 12.3288C11.4822 11.4274 12.2115 10.6958 13.1159 10.6958Z" fill="white" />
                            <path d="M16.2913 15.1999H18.9907V16.4386H19.0293C19.4048 15.7263 20.3237 14.9751 21.6937 14.9751C24.5451 14.9751 25.072 16.8512 25.072 19.2916V24.2609H22.2562V19.8545C22.2562 18.8038 22.2381 17.452 20.7929 17.452C19.3276 17.452 19.104 18.5973 19.104 19.7793V24.2609H16.2913V15.1999Z" fill="white" />
                        </svg>

                    </a>
                    <p className={css`opacity:0.5; font-size: 14px;`}>© {new Date().getFullYear()} Avonova</p></Cell>
            </Grid>
        </footer >
    )
}