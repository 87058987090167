import React from "react";
import { css } from "emotion";
import { Breakpoints, Cell, colors, Grid, spacing, typography } from "@avonova-noa/common";
import { PersonBlock, PersonBlockProps } from "./personBlock";


const teamBlock = css`
    background: ${colors.neutral.light2};
    padding: ${spacing.giant};
    @media (${Breakpoints.mobile}){
        padding: ${spacing.xxl};
    }

    h3 {
        text-align: center;
        ${typography.h3}
    }
`
export interface TeamBlockProps {
    header: string
    people: PersonBlockProps[]
}

export const TeamBlock = ({ header, people }: TeamBlockProps) => {
    return (
        <div className={teamBlock}>
            <Grid columns={3} tablet={{ columns: 1 }}>

                <Cell width={3} tablet={{ width: 1 }}>
                    <h3>{header}</h3>
                </Cell>
                {people.map((person: PersonBlockProps) => {
                    return (
                        <Cell width={1}>
                            <PersonBlock {...person} ></PersonBlock>
                        </Cell>
                    )
                })}
            </Grid>

        </div>
    )
}

