import React from "react"
import { Link } from "gatsby"
import { css, cx } from "emotion"
import { IconArrowRight, typography, Breakpoints, spacing } from "@avonova-noa/common"
import { colors } from "@avonova-noa/common"

const button = css`
  border: 0;
  background: transparent;
  border-radius: 28px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: ${typography.sansSerif};
  font-size: 14px;
  font-weight: 700;
  padding: 13px 40px;
  position: relative;
  text-decoration: none;
  transition: 0.25s;

  + button,
  + a {
    margin-left: 1em;
  }

  &.primary {
    background: #01332a;
    border: 0;
    color: ${colors.green.green5};

    &:hover {
      svg {
        path {
          stroke: ${colors.green.green10};
        }
      }
    }
  }

  &.secondary {
    background: #f5f5f5;
    border: 0;
    color: #595959;
  }

  &.outlined {
    border: 2px solid  ${colors.green.green5};
    color:${colors.green.green5};
  }

  &.blank {
    color: ${colors.green.dark2};
    padding: 0;
    &:hover {
      background: transparent;
    }
  }

  &.underlined {
    padding: 0.8em 1.5em;
    > span {
      border-bottom: 1px solid ${colors.green.green5};
      margin-bottom: 1px;
      color: #00211b;
    }
    &:hover {
      background: transparent;
      border: 0;
      > span {
        border-bottom: 2px solid ${colors.green.green5};
        margin-bottom: 0;
      }
    }
  }

  &.fullwidth-mobile {
    @media screen and (${Breakpoints.mobile}) {
      width: 100%;
      span {
        width: 100%;
        text-align: center;
      }
    }
  }

  &.pill {
    border-radius: 1000px;
    padding: ${spacing.xs} ${spacing.ml};
    line-height: 16px;
  }

  
  // Sizes

  &.xsmall {
    font-size: 12px;
    text-transform: uppercase;
  }

  &.medium {
    font-size: 16px;
  }

  &.large {
    font-size: 20px;
  }

  .wrap {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: .5em;
  }
  
  .icon_wrap {
    display: inline-block;
    height: 1em;
    margin-left: 1em;
    position: relative;
    width: 1em;
    svg {
      position: absolute;
      right: 0;
      transition: .3s;
    }
  }

  &:hover {
    background: ${colors.green.green5};
    color: #01332a;

    svg {
      right: -1em;
    }
  }
`

export enum skins {
  "primary" = "primary",
  "secondary" = "secondary",
  "outlined" = "outlined",
  "underlined" = "underlined",
}

export interface ButtonProps {
  as?: "link" | "linkout" | "submit";
  buttonText: string;
  skin: skins;
  to?: string;
  arrowRight?: boolean;
  customCss?: string;
}

export const Button = ({ as, buttonText, skin, to, arrowRight, customCss }: ButtonProps) =>
  to ? (
    as === "linkout" ? (
      <a target="_blank" href={to} className={`${button} ${skin} ${customCss}`}>
        <span className="wrap">
          <span>{buttonText}</span>
          {arrowRight && <IconArrowRight />}
        </span>
      </a>
    ) :
      as === "link" ? (
        <Link to={to} className={`${button} ${skin} ${customCss}`}>
          <span className="wrap">
            <span>{buttonText}</span>
            {arrowRight && <IconArrowRight />}
          </span>
        </Link>
      ) : (
        <button value={buttonText} className={`${button} ${skin} ${customCss}`}>
          <span className="wrap">
            <span>{buttonText}</span>
            {arrowRight && <IconArrowRight />}
          </span>
        </button>
      )
  ) : (
    <span className={`${button} ${skin} ${customCss}`}>
      <span className="wrap">
        <span>{buttonText}</span>
        {arrowRight && <IconArrowRight />}
      </span>
    </span>
  )
