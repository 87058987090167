import React from "react"
import { Link } from "gatsby"
import { css, cx } from "emotion"

import avonovaLogo from "./../images/avonova_logo.svg"
import helsehjuletLogo from "./../images/helsehjulet_logo.svg"
import { colors, typography } from "@avonova-noa/common"

const logoImg = css`
  width: auto;
  max-width: 100%;
  margin-top: 3px;
`

const mainNavigation = css`
  background: ${colors.green.green11};
  font-family: ${typography.sansSerif};
  font-size: 14px;
  width: 100%;

  @media screen and (min-width: 768px) {
    height: auto;
  }

  .flex {
    max-width: 1280px;
    margin: 0 auto;
    padding: 1.1em;
    text-align: center;

    > div {
      line-height: 0;
    }
  }

  h1 {
    height: 100%;
  }

  img {
    margin: 0;
    max-height: 100%;
  }

  .hide-mobile {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .hide-mobile {
      display: inline-block;
    }
    .flex {
      align-items: center;
      display: flex;
      justify-content: space-between;
      > * {
        flex: 1;
      }
    }
    .center {
      text-align: center;
    }
    .left {
      text-align: left;
    }
    .right {
      text-align: right;
    }
  }

  h1 {
    margin: 0;
    line-height: 0;
  }

  a,
  button {
    margin: 0 10px;
  }

  a {
    color: #fff;
    text-decoration: none;
    transition: 0.25s;
    display: inline-block;
    &:hover {
      color: #09cca6;
    }
  }
`

const Header = ({ siteTitle }: { siteTitle: string }) => (
  <header className={mainNavigation}>
    <div className="flex">
      <div className="left hide-mobile">
        <Link to="https://www.avonova.com/">
          <img className={css`height: 12px;`} src={avonovaLogo} />
        </Link>
      </div>
      <h1 className="center">
        <Link to="/">
          <img className={logoImg} src={helsehjuletLogo} alt={siteTitle}></img>
        </Link>
      </h1>
      <div></div>
      {/* <nav className="right hide-mobile">
        <Link to="/artikler">Artikler</Link>
        <Link to="/podcast">Podcast</Link>
        <Button skin="outlined" buttonText="Ta helsehjultesten" />
      </nav> */}
    </div>
  </header>
)

export default Header
