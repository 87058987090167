import { Link } from "gatsby"
import { css, cx } from "emotion"
import React from "react"
import { colors, typography, spacing, Breakpoints } from "@avonova-noa/common"
import { Blob } from "@avonova-noa/components/src/blob"
import { Button, skins } from './button'


const card = css`
  border-radius: 10px;


  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      margin: 0;
      &:before {
        content: "-";
        float: left;
        margin-right: .25em;
      }
    }
  }

  &.fullHeight {
    height: 100%;
  }

  > .wrap,
  > a {
    height: 100%;
    border-radius: 10px;
    padding: ${spacing.xl} ${spacing.l};
    @media screen and (${Breakpoints.mobile}) {
      padding: ${spacing.m} ${spacing.m} ${spacing.l};
    }
    display: block;
    position: relative;
    transition: .3s;
    ${typography.body};
  }

  /* padding sizes */

  &.no-padding {
    > .wrap,
    > .a {
      padding: 0;
    }
  }

  &.large {
    > .wrap,
    > .a {
      padding: ${spacing.l} ${spacing.ml};
      @media screen and (${Breakpoints.mobile}) {
        padding: ${spacing.ms};
      }
    }
  }
  
  &.giant {
    > .wrap,
    > .a {
      padding-top: ${spacing.giant};
      padding-bottom: ${spacing.giant};
      @media screen and (${Breakpoints.mobile}) {
        padding-top: ${spacing.l};
        padding-bottom: ${spacing.l};
        padding-left: ${spacing.xxs};
        padding-right: ${spacing.xxs};
      }
    }
  }

  &.filled {
    > .wrap,
    > a {
      background: ${colors.neutral.light2};
    }
  }

  &.shadow {
    > .wrap,
    > a {
      box-shadow: 0 0 24px rgba(0, 0, 0, 12%);
    }
    > a:hover {
      box-shadow: 0 0 44px rgba(0, 0, 0, 12%);
    }
  }

  &.centered {
    text-align: center;
    .image {
      margin: 0 auto;
    }
  }

  > a {
    text-decoration: none;
    color: #000;
  }
`
const iconStyle = (hasBlob: boolean) => css`
	position: relative;
	display: flex;
	justify-content: start;
	align-items: center;
	& > div {
	background-color: ${hasBlob ? "transparent" : colors.purple.purple1};
	border-radius: 500px;

	}
	.blob {
		display: ${hasBlob ? "inherit" : "none"};
		position: absolute;
		z-index: -10;
		transform: scale(0.7) translate(-33%, -10%);
	}
`

export enum cardSkins {
  filled = "filled",
  centered = "centered",
  shadow = "shadow",
  giant = "giant",
  large = "large",
  noPadding = "no-padding"
}


export interface CardProps {
  content: JSX.Element | null;
  heading: JSX.Element | null;
  image: JSX.Element | null;
  background: string | undefined;
  icon?: JSX.Element | string | null;
  hasIconBlob?: boolean;
  fullHeight?: boolean;
  to?: string;
  skin?: cardSkins | string | null;
  cta?: string;
}

export const Card = ({ content, to, skin, cta, heading, image, icon, hasIconBlob, background, fullHeight }: CardProps) => {
  return <div
    className={`${card} ${skin ? skin : ""} ${fullHeight ? "fullHeight" : ""}`}
    style={{
      'background':
        background === "orange-01" ? colors.orange.orange1
          : background === "orange-02" ? colors.orange.orange2
            : background === "neutral-01" ? colors.neutral.neutral1
              : background,
      'padding': background ? "auto" : 0
    }}>

    {to ?
      <Link to={to}>
		<div className={iconStyle(hasIconBlob ? hasIconBlob : false)}>
			{icon}
			{hasIconBlob && <Blob blob={"Blob 3"} />}
		</div>
        {image}
        {heading}
        {content}
        {cta && <Button as="linkout" buttonText={cta} to={to} skin={skins.underlined} customCss={css`padding-left: 0 !important; font-size: 20px; `} />}
      </Link>
      : <div className="wrap">
		<div className={iconStyle(hasIconBlob ? hasIconBlob : false)}>
			{icon}
			{hasIconBlob && <Blob blob={"Blob 3"} />}
		</div>
        {image}
        {heading}
        {content}
      </div>
    }

  </div>
}